var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              "status-icon": "",
              rules: _vm.rules,
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Bid Approval Duration",
                  prop: "bidApprovalHours"
                }
              },
              [
                _c("el-input-number", {
                  attrs: { name: "bidApprovalHours", precision: 2, step: 1 },
                  model: {
                    value: _vm.formData.bidApprovalHours,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "bidApprovalHours", $$v)
                    },
                    expression: "formData.bidApprovalHours"
                  }
                }),
                _vm._v(" (mins) "),
                _c("help-text", {
                  attrs: {
                    content:
                      "If not approved within mentioned time, bid will get expired"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: { label: "Bid Expiry Duration", prop: "bidExpiryHours" }
              },
              [
                _c("el-input-number", {
                  attrs: { name: "bidExpiryHours", precision: 2, step: 1 },
                  model: {
                    value: _vm.formData.bidExpiryHours,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "bidExpiryHours", $$v)
                    },
                    expression: "formData.bidExpiryHours"
                  }
                }),
                _vm._v(" (mins) "),
                _c("help-text", {
                  attrs: {
                    content:
                      "If not ordered within mentioned time, bid will get expired"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Bid Timing" } },
              [
                _c("el-time-picker", {
                  attrs: {
                    "is-range": "",
                    "start-placeholder": "Start time",
                    "end-placeholder": "End time",
                    size: "small",
                    "value-format": "HH:mm"
                  },
                  model: {
                    value: _vm.timeRange,
                    callback: function($$v) {
                      _vm.timeRange = $$v
                    },
                    expression: "timeRange"
                  }
                }),
                _c("help-text", {
                  attrs: {
                    content:
                      "Users will be allowed to place bids as per mentioned time"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Booking Amount Threshold",
                  prop: "bookingAmountThreshold"
                }
              },
              [
                _c("el-input-number", {
                  attrs: {
                    name: "bookingAmountThreshold",
                    precision: 2,
                    step: 1
                  },
                  model: {
                    value: _vm.formData.bookingAmountThreshold,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "bookingAmountThreshold", $$v)
                    },
                    expression: "formData.bookingAmountThreshold"
                  }
                }),
                _c("help-text", {
                  attrs: {
                    content:
                      "Any order exceeding this amount will be opted for partial payment"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Bank Information", prop: "bankInformation" } },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    rows: 4,
                    name: "bankInformation",
                    placeholder: "Information"
                  },
                  model: {
                    value: _vm.formData.bankInformation,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "bankInformation", $$v)
                    },
                    expression: "formData.bankInformation"
                  }
                }),
                _c("help-text", {
                  attrs: {
                    content:
                      "Bank information to be displayed in mobile if required"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Floating Banner Text",
                  prop: "floatingBannerText"
                }
              },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    rows: 4,
                    name: "floatingBannerText"
                  },
                  model: {
                    value: _vm.formData.floatingBannerText,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "floatingBannerText", $$v)
                    },
                    expression: "formData.floatingBannerText"
                  }
                }),
                _c("help-text", {
                  attrs: {
                    content: "This text will get displayed on mobile footer"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: { label: "Default Favourite", prop: "defaultFavourite" }
              },
              [
                _c("el-input", {
                  attrs: { name: "defaultFavourite" },
                  model: {
                    value: _vm.formData.defaultFavourite,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "defaultFavourite", $$v)
                    },
                    expression: "formData.defaultFavourite"
                  }
                }),
                _c("help-text", {
                  attrs: {
                    content:
                      "Add comma seperated ids of product variations. If user has no favourites added this be set as default"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Whatsapp Recipients",
                  prop: "whatsappRecipients"
                }
              },
              [
                _vm._l(_vm.formData.whatsappRecipients, function(tag) {
                  return _c(
                    "el-tag",
                    {
                      key: tag,
                      attrs: { closable: "", "disable-transitions": false },
                      on: {
                        close: function($event) {
                          return _vm.handleClose(tag)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(tag) + " ")]
                  )
                }),
                _vm.inputVisible
                  ? _c("el-input", {
                      ref: "saveTagInput",
                      staticClass: "input-new-tag",
                      attrs: { size: "mini" },
                      on: { blur: _vm.handleInputConfirm },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleInputConfirm($event)
                        }
                      },
                      model: {
                        value: _vm.inputValue,
                        callback: function($$v) {
                          _vm.inputValue = $$v
                        },
                        expression: "inputValue"
                      }
                    })
                  : _c(
                      "el-button",
                      {
                        staticClass: "button-new-tag",
                        attrs: { size: "small" },
                        on: { click: _vm.showInput }
                      },
                      [_vm._v(" + New Number ")]
                    )
              ],
              2
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Website version", prop: "webVersion" } },
              [
                _c("el-input", {
                  attrs: { name: "webVersion" },
                  model: {
                    value: _vm.formData.webVersion,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "webVersion", $$v)
                    },
                    expression: "formData.webVersion"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Referral Points", prop: "referralAmount" } },
              [
                _c("el-input-number", {
                  attrs: { precision: 2, step: 1 },
                  model: {
                    value: _vm.formData.referralAmount,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "referralAmount", $$v)
                    },
                    expression: "formData.referralAmount"
                  }
                }),
                _c("help-text", {
                  attrs: {
                    content: "Reward points a user get on new registration"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Referrer Points", prop: "referrerAmount" } },
              [
                _c("el-input-number", {
                  attrs: { precision: 2, step: 1 },
                  model: {
                    value: _vm.formData.referrerAmount,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "referrerAmount", $$v)
                    },
                    expression: "formData.referrerAmount"
                  }
                }),
                _c("help-text", {
                  attrs: {
                    content:
                      "Reward points a referrer get if his code is used during registration"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }