// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.admin-textarea textarea {\n  padding-right: 40px;\n  resize: none;\n  border: none;\n  border-radius: 0px;\n  border-bottom: 1px solid #bfcbd9;\n}\n.el-tag + .el-tag {\n  margin-left: 10px;\n}\n.button-new-tag {\n  margin-left: 10px;\n  height: 32px;\n  line-height: 30px;\n  padding-top: 0;\n  padding-bottom: 0;\n}\n.input-new-tag {\n  width: 90px;\n  margin-left: 10px;\n  vertical-align: bottom;\n}", ""]);
// Exports
exports.locals = {
	"menuBg": "#034073",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409eff"
};
module.exports = exports;
